import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import {
  Chart as ChartJS,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController,
  Colors,
  ArcElement,
} from 'chart.js'
import { Pie } from 'react-chartjs-2'
import 'chartjs-adapter-dayjs-3'
import { Box } from '@mui/material'

ChartJS.register(
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController,
  Colors,
  ArcElement,
)

function PieChart() {
  const { openChart, sourceData } = useSelector((state) => state.data)
  const { employees } = useSelector((state) => state.filters)
  const { successfulTime, theme, selectedChartView } = useSelector(
    (state) => state.settings,
  )

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
        labels: {
          color: theme === 'light' ? '#212121' : '#fff',
          boxWidth: 11,
          boxHeight: 11,
        },
      },
      colors: {
        forceOverride: true,
      },
    },
  }

  const data = useMemo(() => {
    if (sourceData && sourceData.length > 0) {
      const result = {
        labels: [],
        datasets: [
          {
            label: `${selectedChartView == 1 ? 'звонков' : 'минут'}`,
            data: employees.map(() => 0),
          },
        ],
      }

      for (const [index, employee] of employees.entries()) {
        result.labels.push(employee.name)
        for (const call of sourceData) {
          if (Number(call.PORTAL_USER_ID) === employee.id) {
            if (
              selectedChartView == 1 &&
              Number(call.CALL_FAILED_CODE) === 200 &&
              Number(call.CALL_DURATION) > successfulTime
            ) {
              result.datasets[0].data[index]++
            } else if (
              selectedChartView == 2 &&
              Number(call.CALL_FAILED_CODE) === 200 &&
              Number(call.CALL_DURATION) > successfulTime
            ) {
              result.datasets[0].data[index] += parseFloat(call.CALL_DURATION / 60)
            }
          }
        }
      }
      return result
    }
    return {
      labels: [],
      datasets: [],
    }
  }, [openChart, sourceData, selectedChartView])

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
      <Box sx={{ height: 480, width: 480 }}>
        <Pie data={data} options={options} />
      </Box>
    </Box>
  )
}

export default PieChart
