import { createSlice, createAsyncThunk, current } from '@reduxjs/toolkit'
import { apiGetDepartments, apiGetEmployees } from '../../services/getFiltersData'
import { apiGetCalls } from '../../services/getTableData'
import { getResultData } from '../../services/getResultTableData'

export const getDepartments = createAsyncThunk('data/getDepartments', async () => {
  return await apiGetDepartments()
})

export const getEmployees = createAsyncThunk('data/getEmployees', async (ids) => {
  return await apiGetEmployees(ids)
})

export const getCalls = createAsyncThunk(
  'data/getCalls',
  async (_, { getState }) => {
    const { filters, settings } = getState()
    if (filters) {
      const typesIds = filters.typesCalls.map((type) => type.id)
      const employeesIds = filters.employees.map((emp) => emp.id)
      const params = {
        FILTER: {
          '>=CALL_START_DATE': filters.dateStart + ' 00:01:00',
          '<=CALL_START_DATE': filters.dateFinish + ' 23:59:00',
          '=CALL_TYPE': typesIds,
          '=PORTAL_USER_ID': employeesIds,
        },
      }
      const response = await apiGetCalls(params)
      if (response?.length > 0) {
        const result = getResultData(response, filters.employees, settings)
        return {
          sourceData: response,
          resultData: result,
        }
      }
      return {
        sourceData: [],
        resultData: [],
      }
    }
    return {
      sourceData: [],
      resultData: [],
    }
  },
)

const initialState = {
  openDialog: false,
  openChart: false,
  departments: [],
  employees: [],
  calls: [],
  sourceData: [],
  loadingEmployees: false,
  loadingDepartments: false,
  loadingData: false,
  warning: '',
  selectedEmployee: {
    id: null,
    name: null,
  },
}

export const dataSlice = createSlice({
  name: 'data',
  initialState,
  reducers: {
    setDepartments: (state, action) => {
      state.departments = action.payload
    },
    setEmployees: (state, action) => {
      state.employees = action.payload
    },
    setWarning: (state, action) => {
      state.warning = action.payload
    },
    setSelectedEmployee: (state, action) => {
      state.selectedEmployee = action.payload
    },
    setOpenDialog: (state, action) => {
      state.openDialog = action.payload
    },
    setOpenChart: (state, action) => {
      state.openChart = action.payload
    },
  },
  extraReducers: {
    [getDepartments.fulfilled]: (state, action) => {
      state.departments = action.payload
      state.loadingDepartments = false
    },
    [getDepartments.pending]: (state) => {
      state.loadingDepartments = true
    },
    [getEmployees.fulfilled]: (state, action) => {
      state.employees = action.payload
      state.loadingEmployees = false
    },
    [getEmployees.pending]: (state) => {
      state.loadingEmployees = true
    },
    [getCalls.fulfilled]: (state, action) => {
      state.calls = action.payload.resultData
      state.sourceData = action.payload.sourceData
      state.loadingData = false
    },
    [getCalls.pending]: (state) => {
      state.loadingData = true
    },
  },
})

export const {
  setDepartments,
  setEmployees,
  setWarning,
  setSelectedEmployee,
  setOpenDialog,
  setOpenChart,
} = dataSlice.actions

export default dataSlice.reducer
