import React, { useEffect } from 'react'
import Stack from '@mui/material/Stack'
import MultipleSelect from '../ui/MultipleSelect'
import MultipleSelectEmployees from '../ui/MultipleSelectEmployees'
import { useSelector, useDispatch } from 'react-redux'
import {
  setSelectTypesCalls,
  setSelectDepartments,
  setSelectEmployees,
} from '../../redux/filters/filtersSlice'
import {
  getEmployees,
  getDepartments,
  setEmployees,
  setWarning,
} from '../../redux/data/dataSlice'

function FiltersItems() {
  const dispatch = useDispatch()

  const {
    typesCalls: selectTypesCalls,
    departments: selectDepartments,
    employees: selectEmployees,
  } = useSelector((state) => state.filters)

  const typesCalls = [
    { id: 1, name: 'Исходящий' },
    { id: 2, name: 'Входящий' },
    { id: 3, name: 'Входящий с перенаправлением' },
  ]

  const { employees, departments, loadingEmployees, loadingDepartments } =
    useSelector((state) => state.data)

  const getEmployeesData = async () => {
    const ids = selectDepartments.map((item) => Number(item.id))
    dispatch(getEmployees(ids))
    const selEmployees = selectEmployees.filter(
      (emp) => ids.indexOf(emp.departmentId) != -1,
    )
    dispatch(setSelectEmployees(selEmployees))
  }

  useEffect(() => {
    dispatch(getDepartments())
  }, [])

  useEffect(() => {
    if (selectDepartments?.length > 0) {
      getEmployeesData()
    } else {
      dispatch(setEmployees([]))
      dispatch(setSelectEmployees([]))
    }
  }, [dispatch, selectDepartments])

  useEffect(() => {
    let message = ''
    if (selectTypesCalls?.length === 0) {
      message = `${message} Не выбран тип звонка`
    } else if (selectDepartments?.length === 0) {
      message = `${message} Не выбран отдел`
    } else if (selectEmployees?.length === 0) {
      message = `${message} Не выбраны сотрудники`
    }
    dispatch(setWarning(message))
  }, [dispatch, selectTypesCalls, selectDepartments, selectEmployees])

  return (
    <Stack
      direction='row'
      justifyContent='center'
      alignItems='center'
      spacing={2}
      sx={{ pb: 2 }}
    >
      <MultipleSelect
        label='Выберите тип звонка'
        size='small'
        items={typesCalls}
        setValue={(val) => dispatch(setSelectTypesCalls(val))}
        width={380}
        value={selectTypesCalls}
      />
      <MultipleSelect
        label='Выберите отдел'
        size='small'
        items={departments}
        setValue={(val) => dispatch(setSelectDepartments(val))}
        width={380}
        value={selectDepartments}
        loading={loadingDepartments}
      />
      <MultipleSelectEmployees
        label='Выберите сотрудника'
        items={employees}
        setValue={(val) => dispatch(setSelectEmployees(val))}
        width={380}
        value={selectEmployees}
        loading={loadingEmployees}
      />
    </Stack>
  )
}

export default FiltersItems
