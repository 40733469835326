const getResultData = (calls, employees, settings) => {
  let result = []
  for (const [i, employee] of employees.entries()) {
    result[i] = {
      id: employee.id,
      employee: employee.name,
      image: employee.avatar,
      sumCalls: 0,
      sumTime: 0,
      successCalls: 0,
      successTime: 0,
      productiveCalls: 0,
      productiveTime: 0,
      failedCalls: 0,
      missedCalls: 0,
      averageTime: 0,
    }
    for (const call of calls) {
      if (employee.id === Number(call.PORTAL_USER_ID)) {
        // Все звонки
        result[i].sumCalls++
        result[i].sumTime += parseInt(call.CALL_DURATION)
        //Среднее время
        result[i].averageTime = parseInt(result[i].sumTime / result[i].sumCalls)
        if (parseInt(call.CALL_FAILED_CODE) === 200) {
          if (parseInt(call.CALL_DURATION) > settings.productiveTime) {
            // Продуктивные звонки
            result[i].productiveCalls++
            result[i].productiveTime += parseInt(call.CALL_DURATION)
          }
          // Успешные звонки
          if (
            parseInt(call.CALL_DURATION) <= settings.productiveTime &&
            parseInt(call.CALL_DURATION) > settings.successfulTime
          ) {
            result[i].successCalls++
            result[i].successTime += parseInt(call.CALL_DURATION)
          }
          // Неудачные звонки
          if (parseInt(call.CALL_DURATION) <= settings.successfulTime) {
            result[i].failedCalls++
          }
        } else if (parseInt(call.CALL_FAILED_CODE) === 304) {
          result[i].missedCalls++
        } else {
          result[i].failedCalls++
        }
      }
    }
  }

  return result
}

export { getResultData }
