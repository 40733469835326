import { configureStore } from '@reduxjs/toolkit'
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import rootReducer from './rootReducer'

const params = new URLSearchParams(window.location.search)
const domain = params.get('DOMAIN')

const persistConfig = {
  key: '-calls-report-app',
  keyPrefix: domain,
  storage,
  whitelist: ['filters', 'settings'],
}

const store = configureStore({
  reducer: persistReducer(persistConfig, rootReducer()),
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
  devTools: process.env.NODE_ENV === 'development',
})

store.asyncReducers = {}

export const persistor = persistStore(store)

export const injectReducer = (key, reducer) => {
  if (store.asyncReducers[key]) {
    return false
  }
  store.asyncReducers[key] = reducer
  store.replaceReducer(
    persistReducer(persistConfig, rootReducer(store.asyncReducers)),
  )
  persistor.persist()
  return store
}

export default store
