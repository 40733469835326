import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  openDrawer: false,
  theme: 'light',
  successfulTime: 20,
  productiveTime: 120,
  viewChart: 'bar',
  selectedChartView: 1,
  effectiveness: 'successful',
  columns: {
    productiveCalls: true,
    successCalls: true,
    failedCalls: true,
    missedCalls: true,
    sumCalls: true,
    productiveTime: true,
    successTime: true,
    averageTime: true,
    sumTime: true,
    effectProdCalls: true,
    effectSuccessCalls: true,
  },
  filterModel: { items: [] },
  pageSize: 20,
}

export const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    setOpenDrawer: (state, action) => {
      state.openDrawer = action.payload
    },
    setTheme: (state, action) => {
      state.theme = action.payload
    },
    setSuccessfulTime: (state, action) => {
      state.successfulTime = action.payload
    },
    setProductiveTime: (state, action) => {
      state.productiveTime = action.payload
    },
    setEffectiveness: (state, action) => {
      state.effectiveness = action.payload
    },
    setFilterModel: (state, action) => {
      state.filterModel = action.payload
    },
    setСolumns: (state, action) => {
      state.columns = action.payload
    },
    setPageSize: (state, action) => {
      state.pageSize = action.payload
    },
    setViewChart: (state, action) => {
      state.viewChart = action.payload
    },
    setSelectedChartView: (state, action) => {
      state.selectedChartView = action.payload
    },
  },
})

export const {
  setOpenDrawer,
  setTheme,
  setSuccessfulTime,
  setProductiveTime,
  setFailedTime,
  setEffectiveness,
  setFilterModel,
  setСolumns,
  setPageSize,
  setViewChart,
  setSelectedChartView,
} = settingsSlice.actions

export default settingsSlice.reducer
