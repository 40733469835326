import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import MenuItem from '@mui/material/MenuItem'
import TextField from '@mui/material/TextField'
import IconButton from '@mui/material/IconButton'
import Button from '@mui/material/Button'
import CloseIcon from '@mui/icons-material/Close'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import {
  setOpenDrawer,
  setTheme,
  setSuccessfulTime,
  setProductiveTime,
} from '../../redux/settings/settingsSlice'
import { getCalls } from '../../redux/data/dataSlice'

function SettingsForm() {
  const dispatch = useDispatch()

  const { theme, successfulTime, productiveTime } = useSelector(
    (state) => state.settings,
  )

  const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: 'space-between',
  }))

  return (
    <Box sx={{ width: 260 }} role='presentation'>
      <DrawerHeader>
        <Typography sx={{ ml: 1 }} variant='h5'>
          Настройки
        </Typography>
        <IconButton onClick={() => dispatch(setOpenDrawer(false))}>
          <CloseIcon />
        </IconButton>
      </DrawerHeader>
      <Box sx={{ m: 1 }}>
        <TextField
          sx={{ width: 230, my: 2 }}
          required
          label='Успешные звонки более N сек.'
          defaultValue={successfulTime}
          type='number'
          onChange={(e) => dispatch(setSuccessfulTime(e.target.value))}
        />
        <TextField
          sx={{ width: 230, my: 2 }}
          required
          label='Продуктивные звонки более N сек.'
          defaultValue={productiveTime}
          type='number'
          onChange={(e) => dispatch(setProductiveTime(e.target.value))}
        />
        <Box
          sx={{
            width: 230,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Button
            onClick={() => dispatch(getCalls())}
            startIcon={<CheckCircleIcon />}
            variant='outlined'
            size='large'
          >
            Применить
          </Button>
        </Box>
        <TextField
          sx={{ width: 230, mt: 10 }}
          value={theme}
          onChange={(e) => dispatch(setTheme(e.target.value))}
          variant='outlined'
          label='Выбрать оформление:'
          select
        >
          <MenuItem value={'light'}>Светлая тема</MenuItem>
          <MenuItem value={'dark'}>Темная тема</MenuItem>
        </TextField>
      </Box>
    </Box>
  )
}

export default SettingsForm
