import { callBatch } from './bitrix24ApiServices'

const apiGetLeads = async (leadsIds) => {
  if (leadsIds?.length > 0) {
    const params = {
      filter: {
        '=ID': leadsIds,
      },
      select: ['ID', 'TITLE'],
    }
    const response = await callBatch('crm.lead.list', params)
    if (response) {
      return response.map((item) => {
        return {
          crmName: 'LEAD',
          id: item.ID,
          name: item.TITLE,
        }
      })
    }
    return []
  }
  return []
}

const apiGetContacts = async (contactsIds) => {
  if (contactsIds?.length > 0) {
    const params = {
      filter: {
        '=ID': contactsIds,
      },
      select: ['ID', 'NAME', 'LAST_NAME'],
    }
    const response = await callBatch('crm.contact.list', params)
    if (response) {
      return response.map((item) => {
        return {
          crmName: 'CONTACT',
          id: item.ID,
          name: `${item.NAME} ${item.LAST_NAME ?? ''}`,
        }
      })
    }
    return []
  }
  return []
}

const apiGetCompanies = async (companiesIds) => {
  if (companiesIds?.length > 0) {
    const params = {
      filter: {
        '=ID': companiesIds,
      },
      select: ['ID', 'TITLE'],
    }
    const response = await callBatch('crm.company.list', params)
    if (response) {
      return response.map((item) => {
        return {
          crmName: 'COMPANY',
          id: item.ID,
          name: item.TITLE,
        }
      })
    }
    return []
  }
  return []
}

const getCrmItemsNames = async (calls) => {
  let leadsIds = [],
    contactsIds = [],
    companiesIds = []

  for (let call of calls) {
    if (call.CRM_ENTITY_TYPE === 'COMPANY') {
      companiesIds.push(call.CRM_ENTITY_ID)
    } else if (call.CRM_ENTITY_TYPE === 'CONTACT') {
      contactsIds.push(call.CRM_ENTITY_ID)
    } else if (call.CRM_ENTITY_TYPE === 'LEAD') {
      leadsIds.push(call.CRM_ENTITY_ID)
    }
  }

  const leadsNames = await apiGetLeads(leadsIds)
  const contactsNames = await apiGetContacts(contactsIds)
  const companiesNames = await apiGetCompanies(companiesIds)
  const names = [...leadsNames, ...contactsNames, ...companiesNames]

  const result = calls.map((call) => {
    const name = names.find(
      (name) =>
        call.CRM_ENTITY_TYPE === name.crmName && call.CRM_ENTITY_ID === name.id,
    )
    return name && name?.name
      ? { ...call, CRM_ENTITY_NAME: name?.name }
      : { ...call, CRM_ENTITY_NAME: '' }
  })
  return result
}

export { getCrmItemsNames }
