import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarFilterButton,
} from '@mui/x-data-grid'
import { GridLocaleText } from '../../locales/tableGridLocale'
import { getCrmItemsNames } from '../../services/getCrmItemsNames'
import dayjs from 'dayjs'
import 'dayjs/locale/ru'

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarFilterButton />
    </GridToolbarContainer>
  )
}

function Table({ row }) {
  const { sourceData, selectedEmployee } = useSelector((state) => state.data)
  const { successfulTime, productiveTime } = useSelector((state) => state.settings)
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)

  function statusCall(call) {
    console.log('call', call)
    if (Number(call.CALL_FAILED_CODE) === 200) {
      if (Number(call.CALL_DURATION) > productiveTime) {
        return 'Продуктивный'
      } else if (
        Number(call.CALL_DURATION) <= productiveTime &&
        Number(call.CALL_DURATION) > successfulTime
      ) {
        return 'Успешный'
      } else {
        return 'Неудачный'
      }
    } else {
      return 'Неудачный'
    }
  }

  function typeCall(type) {
    switch (type) {
      case '1':
        return 'Исходящий'
      case '2':
        return 'Входящий'
      case '3':
        return 'Входящий с перенаправлением'
    }
  }

  function callTime(sec) {
    let hours = Math.floor(sec / 3600)
    sec %= 3600
    let minutes = Math.floor(sec / 60)
    let seconds = sec % 60
    minutes = String(minutes).padStart(2, '0')
    hours = String(hours).padStart(2, '0')
    seconds = String(seconds).padStart(2, '0')
    return hours + ':' + minutes + ':' + seconds
  }

  useEffect(() => {
    const source = sourceData.filter(
      (call) => Number(call.PORTAL_USER_ID) === selectedEmployee.id,
    )
    load(source)
    async function load(source) {
      setData([])
      setLoading(true)
      const calls = await getCrmItemsNames(source)
      const result = calls.map((call) => {
        return {
          id: call?.ID,
          employee: selectedEmployee.name,
          phone: call?.PHONE_NUMBER,
          type: typeCall(call?.CALL_TYPE),
          time: callTime(call?.CALL_DURATION),
          dateTime: dayjs(call?.CALL_START_DATE).format('DD.MM.YYYY HH:mm'),
          status: statusCall(call),
          crmName: call?.CRM_ENTITY_TYPE?.toLowerCase(),
          itemCrmName: call?.CRM_ENTITY_NAME ?? '',
          itemCrmId: call?.CRM_ENTITY_ID,
        }
      })
      setData(result)
      setLoading(false)
    }
  }, [sourceData, selectedEmployee])

  return (
    <div
      style={{
        height: 600,
        width: '100%',
        marginBottom: 10,
        paddingRight: 10,
        paddingLeft: 10,
      }}
    >
      <DataGrid
        rows={data}
        columns={row}
        rowHeight={37}
        pageSize={10}
        loading={loading}
        disableSelectionOnClick
        components={{ Toolbar: CustomToolbar }}
        localeText={GridLocaleText}
        disableColumnMenu
        componentsProps={{
          pagination: {
            labelRowsPerPage: 'Строк на странице:',
          },
        }}
        sx={{
          '&.MuiDataGrid-root .MuiDataGrid-cell': {
            borderBottomColor: '#525c69',
          },
          '&.MuiDataGrid-root .MuiDataGrid-columnHeaders': {
            borderBottomColor: '#525c69',
          },
          '&.MuiDataGrid-root .MuiDataGrid-footerContainer': {
            borderTopColor: '#525c69',
          },
          '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': { py: '8px' },
          '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': {
            py: '15px',
          },
          '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell': {
            py: '22px',
          },
          '&.MuiDataGrid-root .MuiDataGrid-iconSeparator': { color: '#525c69' },
          bgcolor: 'background.paper',
        }}
      />
    </div>
  )
}

export default Table
