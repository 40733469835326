import React from 'react'
import { useSelector } from 'react-redux'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import CssBaseline from '@mui/material/CssBaseline'
import { Box } from '@mui/material'
import { grey } from '@mui/material/colors'
import Header from './components/header'
import Table from './components/table'
import { ruRU } from '@mui/x-data-grid'
import { ruRU as pickersRU } from '@mui/x-date-pickers'
import { ruRU as coreRU } from '@mui/material/locale'

const App = () => {
  const { theme: selectTheme } = useSelector((state) => state.settings)

  const theme = React.useMemo(
    () =>
      createTheme(
        {
          palette: {
            mode: selectTheme,
            ...(selectTheme === 'light'
              ? {
                  primary: {
                    main: grey[700],
                  },
                  divider: grey[800],
                  background: {
                    default: grey[100],
                    paper: grey[200],
                  },
                  text: {
                    primary: grey[900],
                    secondary: grey[800],
                  },
                }
              : {
                  primary: {
                    main: grey[50],
                  },
                  divider: '#354050',
                  background: {
                    default: '#1f2937',
                    paper: '#354050',
                  },
                  text: {
                    primary: grey[100],
                    secondary: grey[300],
                  },
                }),
          },
          components: {
            MuiDataGrid: {
              styleOverrides: {
                root: {
                  borderColor: '#525c69',
                  borderTopColor: '#525c69',
                  borderBottomColor: '#525c69',
                  columnHeaders: {
                    borderTopColor: '#525c69',
                    borderBottomColor: '#525c69',
                  },
                },
              },
            },
          },
        },
        ruRU,
        pickersRU,
        coreRU,
      ),
    [selectTheme],
  )

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Box sx={{ p: 2 }}>
        <Header />
        <Table />
      </Box>
    </ThemeProvider>
  )
}

export default App
