import React from 'react'
import { useDispatch } from 'react-redux'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { Stack, Box, Tooltip } from '@mui/material'
import { setOpenDialog } from '../../redux/data/dataSlice'
import { setSelectedEmployee } from '../../redux/data/dataSlice'

const DetailEmployee = ({ data }) => {
  const dispatch = useDispatch()
  return (
    <Stack direction='row' justifyContent='center' alignItems='center' spacing={2}>
      <Tooltip title='Детализация звонков' placement='right-start'>
        <InfoOutlinedIcon
          sx={{ cursor: 'pointer' }}
          onClick={() => {
            dispatch(setOpenDialog(true))
            dispatch(setSelectedEmployee({ id: data?.id, name: data?.employee }))
          }}
        />
      </Tooltip>
      <Box>{data?.employee}</Box>
    </Stack>
  )
}

const row = [
  {
    field: 'employee',
    headerName: 'Ответственный',
    width: 240,
    type: 'string',
    hideable: false,
    renderCell: (params) => <DetailEmployee data={params.row} />,
  },
  {
    field: 'productiveCalls',
    headerName: 'Продуктивных звонков',
    description: 'Продуктивных звонков',
    renderHeader: () => 'Продуктивных',
    width: 120,
    type: 'number',
    align: 'right',
  },
  {
    field: 'successCalls',
    headerName: 'Успешных звонков',
    description: 'Успешных звонков',
    renderHeader: () => 'Успешных',
    width: 90,
    type: 'number',
    align: 'right',
  },
  {
    field: 'failedCalls',
    headerName: 'Неудачных звонков',
    description: 'Неудачных звонков',
    renderHeader: () => 'Неудачных',
    width: 95,
    type: 'number',
    align: 'right',
  },
  {
    field: 'missedCalls',
    headerName: 'Пропущенных звонков',
    description: 'Пропущенных звонков',
    renderHeader: () => 'Пропущенных',
    width: 115,
    type: 'number',
    align: 'right',
  },
  {
    field: 'sumCalls',
    headerName: 'Всего звонков',
    description: 'Всего звонков',
    renderHeader: () => 'Всего',
    width: 60,
    type: 'number',
    align: 'right',
  },
  {
    field: 'productiveTime',
    headerName: 'Продуктивное время',
    description: 'Продуктивное время',
    renderHeader: () => 'Продуктивноe',
    filterable: false,
    width: 120,
    type: 'string',
    align: 'right',
    valueFormatter: ({ value }) => call_time(value),
    renderCell: (params) => call_time(params.row?.productiveTime),
  },
  {
    field: 'successTime',
    headerName: 'Успешное время',
    description: 'Успешное время',
    renderHeader: () => 'Успешноe',
    filterable: false,
    width: 90,
    type: 'string',
    align: 'right',
    valueFormatter: ({ value }) => call_time(value),
    renderCell: (params) => call_time(params.row?.successTime),
  },
  {
    field: 'averageTime',
    headerName: 'Среднее время',
    description: 'Среднее время',
    renderHeader: () => 'Среднеe',
    filterable: false,
    width: 80,
    type: 'string',
    align: 'right',
    valueFormatter: ({ value }) => call_time(value),
    renderCell: (params) => call_time(params.row?.averageTime),
  },
  {
    field: 'sumTime',
    headerName: 'Всего времени',
    description: 'Всего времени',
    renderHeader: () => 'Всего',
    filterable: false,
    width: 80,
    type: 'string',
    align: 'right',
    valueFormatter: ({ value }) => call_time(value),
    renderCell: (params) => call_time(params.row?.sumTime),
  },
  {
    field: 'effectProdCalls',
    headerName: 'Эффективность прод. звон.',
    renderHeader: () => 'Продуктивных',
    filterable: false,
    width: 120,
    type: 'string',
    align: 'right',
    valueGetter: (params) =>
      `${
        Math.round((params.row.productiveCalls * 100) / params.row.sumCalls) || 0
      }%`,
  },
  {
    field: 'effectSuccessCalls',
    headerName: 'Эффективность усп. звон.',
    renderHeader: () => 'Успешных',
    filterable: false,
    width: 90,
    type: 'string',
    align: 'right',
    valueGetter: (params) =>
      `${Math.round((params.row.successCalls * 100) / params.row.sumCalls) || 0}%`,
  },
]

function call_time(sec) {
  let hours = Math.floor(sec / 3600)
  sec %= 3600
  let minutes = Math.floor(sec / 60)
  let seconds = sec % 60
  minutes = String(minutes).padStart(2, '0')
  hours = String(hours).padStart(2, '0')
  seconds = String(seconds).padStart(2, '0')
  return hours + ':' + minutes + ':' + seconds
}

export default row
