import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import {
  gridPageCountSelector,
  gridPageSelector,
  useGridApiContext,
  useGridSelector,
} from '@mui/x-data-grid'
import { Stack, Box, Paper, Pagination } from '@mui/material'
import { styled } from '@mui/material/styles'

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}))

function Footer() {
  const apiRef = useGridApiContext()
  const page = useGridSelector(apiRef, gridPageSelector)
  const pageCount = useGridSelector(apiRef, gridPageCountSelector)
  const { calls } = useSelector((state) => state.data)
  const [result, setResult] = useState({})

  function call_time(sec) {
    let hours = Math.floor(sec / 3600)
    sec %= 3600
    let minutes = Math.floor(sec / 60)
    let seconds = sec % 60
    minutes = String(minutes).padStart(2, '0')
    hours = String(hours).padStart(2, '0')
    seconds = String(seconds).padStart(2, '0')
    return hours + ':' + minutes + ':' + seconds
  }

  useEffect(() => {
    let SuccessTime = 0
    let ProductiveTime = 0
    let AverageTime = 0
    let SumTime = 0
    let SuccessEffectiveCalls = 0
    let ProductiveEffectiveCalls = 0
    let res = {
      allProductiveCalls: 0,
      allProductiveTime: '00:00:00',
      allSuccessCalls: 0,
      allSuccessTime: '00:00:00',
      allAverageTime: '00:00:00',
      allFailedCalls: 0,
      allMissedCalls: 0,
      allSumTime: '00:00:00',
      allSumCalls: 0,
      allSuccessEffectiveCalls: '0%',
      allProductiveEffectiveCalls: '0%',
    }
    for (const call of calls) {
      res.allProductiveCalls += call.productiveCalls
      res.allSuccessCalls += call.successCalls
      res.allFailedCalls += call.failedCalls
      res.allMissedCalls += call.missedCalls
      res.allSumCalls += call.sumCalls
      SuccessTime += call.successTime
      ProductiveTime += call.productiveTime
      AverageTime += call.averageTime
      AverageTime += call.averageTime
      SumTime += call.sumTime
      SuccessEffectiveCalls = (res.allSuccessCalls * 100) / res.allSumCalls || 0
      ProductiveEffectiveCalls =
        (res.allProductiveCalls * 100) / res.allSumCalls || 0
    }
    res.allProductiveTime = call_time(ProductiveTime)
    res.allSuccessTime = call_time(SuccessTime)
    res.allAverageTime = call_time(AverageTime)
    res.allSumTime = call_time(SumTime)
    res.allSuccessEffectiveCalls = `${Math.round(SuccessEffectiveCalls)}%`
    res.allProductiveEffectiveCalls = `${Math.round(ProductiveEffectiveCalls)}%`
    setResult(res)
  }, [calls])

  return (
    <Stack
      direction='row'
      justifyContent='space-between'
      alignItems='center'
      sx={{ padding: '10px' }}
    >
      <Box sx={{ padding: '10px' }}>
        {calls?.length > 0 && (
          // <Item>
          //   <Box sx={{ display: 'flex', gap: 2 }}>
          //     <div>Звонков:</div>
          //     <div>Продуктивных: {result.allProductiveCalls}</div>
          //     <div>Успешных: {result.allSuccessCalls}</div>
          //     <div>Неудачных: {result.allFailedCalls}</div>
          //     <div>Пропущенных: {result.allMissedCalls}</div>
          //     <div>Всего: {result.allSumCalls}</div>
          //   </Box>
          //   <Box sx={{ display: 'flex', gap: 2 }}>
          //     <div>Время:</div>
          //     <div>Продуктивное: {result.allProductiveTime}</div>
          //     <div>Успешное: {result.allSuccessTime}</div>
          //     <div>Среднее: {result.allAverageTime}</div>
          //     <div>Общее: {result.allSumTime}</div>
          //   </Box>
          //   <Box sx={{ display: 'flex', gap: 2 }}>
          //     <div>Эффективность:</div>
          //     <div>Продуктивных звонков: {result.allProductiveEffectiveCalls}</div>
          //     <div>Успешных звонков: {result.allSuccessEffectiveCalls}</div>
          //   </Box>
          // </Item>
          <Item>
            <Box sx={{ display: 'flex', gap: 2 }}>
              <div>
                <b>Продуктивных:</b> {result.allProductiveCalls} /{' '}
                {result.allProductiveTime} / {result.allProductiveEffectiveCalls}
              </div>
              <div>
                <b>Успешных:</b> {result.allSuccessCalls} / {result.allSuccessTime} /{' '}
                {result.allSuccessEffectiveCalls}
              </div>
              <div>
                <b>Пропущенных:</b> {result.allMissedCalls}
              </div>
              <div>
                <b>Всего:</b> {result.allSumCalls} / {result.allSumTime}
              </div>
            </Box>
          </Item>
        )}
      </Box>
      <Pagination
        count={pageCount}
        page={page + 1}
        onChange={(event, value) => apiRef.current.setPage(value - 1)}
      />
    </Stack>
  )
}

export default Footer
