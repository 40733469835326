import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import CloseIcon from '@mui/icons-material/Close'
import { Slide, Link, IconButton, Stack, Dialog, Typography } from '@mui/material'
import { setOpenDialog } from '../../redux/data/dataSlice'
import Table from './Table'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />
})

const DetailCrmItem = ({ data }) => {
  const params = new URLSearchParams(window.location.search)
  const domain = params.get('DOMAIN')
  return (
    <Link
      href={`https://${domain}/crm/${data.crmName}/details/${data.itemCrmId}/`}
      rel='noreferrer'
      target='_blank'
    >
      {data.itemCrmName}
    </Link>
  )
}

const typeCrmMap = {
  lead: 'Лид',
  contact: 'Контакт',
  company: 'Компания',
}

function FullScreenDialog() {
  const dispatch = useDispatch()
  const { openDialog } = useSelector((state) => state.data)

  const handleClose = () => {
    dispatch(setOpenDialog(false))
  }

  const TypeCrmItem = (data) => typeCrmMap[data]

  const row = [
    {
      field: 'employee',
      headerName: 'Сотрудник',
      description: 'Сотрудник',
      width: 200,
      type: 'string',
      hideable: false,
    },
    {
      field: 'phone',
      headerName: 'Номер телефона',
      description: 'Номер телефона',
      width: 140,
      type: 'string',
    },
    {
      field: 'type',
      headerName: 'Тип звонка',
      description: 'Тип звонка',
      width: 120,
      type: 'string',
    },
    {
      field: 'time',
      headerName: 'Время звонка',
      description: 'Время звонка',
      width: 120,
      type: 'string',
    },
    {
      field: 'dateTime',
      headerName: 'Дата звонка',
      description: 'Дата звонка',
      width: 140,
      type: 'dateTime',
    },
    {
      field: 'status',
      headerName: 'Статус звонка',
      description: 'Статус звонка',
      width: 140,
      type: 'string',
    },
    {
      field: 'crmName',
      headerName: 'Тип CRM',
      description: 'Тип CRM',
      width: 100,
      type: 'string',
      valueFormatter: ({ value }) => TypeCrmItem(value),
      renderCell: ({ value }) => TypeCrmItem(value),
    },
    {
      field: 'itemCrmName',
      headerName: 'CRM наименование',
      description: 'CRM наименование',
      minWidth: 300,
      type: 'string',
      renderCell: (params) => <DetailCrmItem data={params.row} />,
    },
  ]

  return (
    <div>
      <Dialog
        fullScreen
        open={openDialog}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <Stack
          direction='row'
          justifyContent='space-between'
          alignItems='center'
          spacing={2}
          p={2}
          sx={{ bgcolor: 'background.paper' }}
        >
          <Typography sx={{ ml: 2, flex: 1 }} variant='h6' component='div'>
            Детализация звонков
          </Typography>
          <IconButton
            edge='start'
            color='inherit'
            onClick={handleClose}
            aria-label='close'
          >
            <CloseIcon />
          </IconButton>
        </Stack>
        <Table row={row} />
      </Dialog>
    </div>
  )
}

export default FullScreenDialog
