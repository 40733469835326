import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import CloseIcon from '@mui/icons-material/Close'
import BarChartIcon from '@mui/icons-material/BarChart'
import TimelineIcon from '@mui/icons-material/Timeline'
import PieChartIcon from '@mui/icons-material/PieChart'
import {
  Slide,
  IconButton,
  Stack,
  Dialog,
  Typography,
  Box,
  Radio,
  RadioGroup,
  FormControlLabel,
} from '@mui/material'
import { setOpenChart } from '../../redux/data/dataSlice'
import {
  setViewChart,
  setSelectedChartView,
} from '../../redux/settings/settingsSlice'
import dayjs from 'dayjs'
import BarChart from './BarChart'
import LineChart from './LineChart'
import PieChart from './PieChart'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />
})

function Chart() {
  const dispatch = useDispatch()
  const { openChart } = useSelector((state) => state.data)
  const { dateStart, dateFinish } = useSelector((state) => state.filters)
  const { viewChart, selectedChartView } = useSelector((state) => state.settings)

  const handleClose = () => {
    dispatch(setOpenChart(false))
  }

  return (
    <Dialog
      fullScreen
      open={openChart}
      onClose={handleClose}
      TransitionComponent={Transition}
    >
      <Stack
        direction='row'
        justifyContent='space-between'
        alignItems='center'
        spacing={2}
        m={2}
      >
        <Box>
          <Typography sx={{ flex: 1 }} variant='h7' component='div'>
            Период:{' '}
            {`${dayjs(dateStart).format('DD.MM.YYYY')} - ${dayjs(dateFinish).format(
              'DD.MM.YYYY',
            )}`}
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
          <Typography sx={{ flex: 1 }} variant='h7' component='div'>
            Тип графика:
          </Typography>
          <IconButton
            edge='start'
            color={viewChart === 'bar' ? 'success' : 'inherit'}
            onClick={() => dispatch(setViewChart('bar'))}
            aria-label='close'
          >
            <BarChartIcon />
          </IconButton>
          <IconButton
            edge='start'
            color={viewChart === 'line' ? 'success' : 'inherit'}
            onClick={() => dispatch(setViewChart('line'))}
            aria-label='close'
          >
            <TimelineIcon />
          </IconButton>
          <IconButton
            edge='start'
            color={viewChart === 'pie' ? 'success' : 'inherit'}
            onClick={() => dispatch(setViewChart('pie'))}
            aria-label='close'
          >
            <PieChartIcon />
          </IconButton>
        </Box>
        <Box>
          <RadioGroup
            row
            value={selectedChartView}
            onChange={(event) => dispatch(setSelectedChartView(event.target.value))}
          >
            <FormControlLabel value={1} control={<Radio />} label='По звонкам' />
            <FormControlLabel value={2} control={<Radio />} label='По времени' />
          </RadioGroup>
        </Box>
        <Box>
          <IconButton
            edge='start'
            color='inherit'
            onClick={handleClose}
            aria-label='close'
          >
            <CloseIcon />
          </IconButton>
        </Box>
      </Stack>
      <Stack m={2}>
        {viewChart === 'bar' && <BarChart />}
        {viewChart === 'line' && <LineChart />}
        {viewChart === 'pie' && <PieChart />}
      </Stack>
    </Dialog>
  )
}

export default Chart
