import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import {
  Chart as ChartJS,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController,
  Colors,
  TimeScale,
} from 'chart.js'
import { Chart } from 'react-chartjs-2'
import dayjs from 'dayjs'
import 'chartjs-adapter-dayjs-3'

ChartJS.register(
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController,
  Colors,
  TimeScale,
)

function BarChart() {
  const { openChart, sourceData } = useSelector((state) => state.data)
  const { dateStart, dateFinish, employees } = useSelector((state) => state.filters)
  const { successfulTime, theme, selectedChartView } = useSelector(
    (state) => state.settings,
  )

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
        labels: {
          color: theme === 'light' ? '#212121' : '#fff',
          boxWidth: 11,
          boxHeight: 11,
        },
      },
      colors: {
        forceOverride: true,
      },
    },
    scales: {
      x: {
        type: 'time',
        time: {
          isoWeekday: 1,
          unit: 'day',
          displayFormats: {
            day: 'DD.MM.YY',
          },
          tooltipFormat: 'DD.MM.YY',
        },
        ticks: {
          color: theme === 'light' ? '#212121' : '#fff',
        },
      },
      y: {
        ticks: {
          color: theme === 'light' ? '#212121' : '#fff',
          callback: function (value, index, ticks) {
            return `${value} ${selectedChartView == 1 ? 'звон.' : 'мин.'}`
          },
        },
      },
    },
  }

  const getRangeDates = () => {
    let dates = []
    let currentDate = dayjs(dateStart)
    while (currentDate <= dayjs(dateFinish)) {
      dates.push(dayjs(currentDate).format('YYYY-MM-DD'))
      currentDate = dayjs(currentDate).add(1, 'day')
    }
    return dates
  }

  const data = useMemo(() => {
    if (sourceData && sourceData.length > 0) {
      const result = {
        labels: [],
        datasets: [
          {
            type: 'line',
            label: 'Среднее значение',
            borderWidth: 2,
            fill: false,
            data: [],
          },
        ],
      }
      let average = []
      result.labels = getRangeDates()
      for (const [index, employee] of employees.entries()) {
        for (const date of result.labels) {
          average[date] = 0
          if (result.datasets[index + 1]) {
            result.datasets[index + 1].data[date] = 0
          } else {
            result.datasets[index + 1] = {
              type: 'bar',
              label: employee.name,
              data: { [date]: 0 },
              employee_id: employee.id,
            }
          }
        }
      }

      for (const call of sourceData) {
        const date = dayjs(call.CALL_START_DATE).format('YYYY-MM-DD')
        for (const [index, item] of result.datasets.entries()) {
          if (Number(call.PORTAL_USER_ID) === item.employee_id) {
            for (const [key, _] of Object.entries(item.data)) {
              if (index > 0 && date == key) {
                if (
                  selectedChartView == 1 &&
                  Number(call.CALL_FAILED_CODE) === 200 &&
                  Number(call.CALL_DURATION) > successfulTime
                ) {
                  result.datasets[index].data[key]++
                  average[key]++
                } else if (
                  selectedChartView == 2 &&
                  Number(call.CALL_FAILED_CODE) === 200 &&
                  Number(call.CALL_DURATION) > successfulTime
                ) {
                  result.datasets[index].data[key] += parseFloat(
                    Number(call.CALL_DURATION) / 60,
                  )
                  average[key] += parseFloat(Number(call.CALL_DURATION) / 60)
                }
              }
            }
          }
        }
      }

      for (const [_, value] of Object.entries(average)) {
        result.datasets[0].data.push(
          Math.round(value / (result.datasets.length - 1)),
        )
      }
      return result
    }
    return {
      labels: getRangeDates(),
      datasets: [],
    }
  }, [openChart, sourceData, selectedChartView])

  return <Chart type='bar' data={data} options={options} height={90} />
}

export default BarChart
