import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Alert from '@mui/material/Alert'
import Stack from '@mui/material/Stack'
import Drawer from '@mui/material/Drawer'
import FiltersItems from './FiltersItems'
import FiltersDate from './FiltersDate'
import SettingsForm from './SettingsForm'
import { setOpenDrawer } from '../../redux/settings/settingsSlice'

export default function Header() {
  const dispatch = useDispatch()
  const { warning } = useSelector((state) => state.data)
  const { openDrawer } = useSelector((state) => state.settings)

  return (
    <Stack sx={{ width: '100%' }} spacing={2}>
      {warning && <Alert severity='warning'>{warning}</Alert>}
      <Drawer
        anchor={'left'}
        open={openDrawer}
        onClose={() => dispatch(setOpenDrawer(false))}
      >
        <SettingsForm />
      </Drawer>
      <FiltersItems />
      <FiltersDate />
    </Stack>
  )
}
