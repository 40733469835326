import { callMethod } from './bitrix24ApiServices'
import DefaultAvatar from '../assets/images/avatar.svg'

const apiGetDepartments = async () => {
  const params = {
    sort: 'NAME',
    order: 'ASC',
    select: ['ID', 'NAME'],
  }
  const response = await callMethod('department.get', params)
  if (response) {
    const result = []
    response.forEach((item) => {
      result.push({
        id: item.ID,
        name: item.NAME,
      })
    })
    return result
  } else {
    return []
  }
}

const apiGetEmployees = async (ids = []) => {
  const params = {
    sort: 'UF_DEPARTMENT',
    order: 'ASC',
    filter: {
      UF_DEPARTMENT: ids,
      USER_TYPE: 'employee',
      ACTIVE: 1,
    },
    select: ['ID', 'NAME', 'LAST_NAME', 'PERSONAL_PHOTO', 'UF_DEPARTMENT'],
  }
  const response = await callMethod('user.get', params)
  if (response) {
    const result = []
    response.forEach((item) => {
      result.push({
        id: Number(item.ID),
        name: `${item.NAME} ${item.LAST_NAME}`,
        avatar: item.PERSONAL_PHOTO ?? DefaultAvatar,
        departmentId: Number(item.UF_DEPARTMENT),
      })
    })
    return result
  } else {
    return []
  }
}

export { apiGetDepartments, apiGetEmployees }
