import { createSlice } from '@reduxjs/toolkit'
import dayjs from 'dayjs'

const initialState = {
  typesCalls: [
    { id: 1, name: 'Исходящий' },
    { id: 2, name: 'Входящий' },
    { id: 3, name: 'Входящий с перенаправлением' },
  ],
  departments: [],
  employees: [],
  dateStart: dayjs().format('YYYY-MM-DD'),
  dateFinish: dayjs().format('YYYY-MM-DD'),
  period: 'day',
}

export const filtersSlice = createSlice({
  name: 'filters',
  initialState,
  reducers: {
    setSelectTypesCalls: (state, action) => {
      state.typesCalls = action.payload
    },
    setSelectDepartments: (state, action) => {
      state.departments = action.payload
    },
    setSelectEmployees: (state, action) => {
      state.employees = action.payload
    },
    setSelectDateStart: (state, action) => {
      state.dateStart = action.payload
    },
    setSelectDateFinish: (state, action) => {
      state.dateFinish = action.payload
    },
    setSelectPeriod: (state, action) => {
      state.period = action.payload
    },
  },
})

export const {
  setSelectTypesCalls,
  setSelectDepartments,
  setSelectEmployees,
  setSelectDateStart,
  setSelectDateFinish,
  setSelectPeriod,
} = filtersSlice.actions

export default filtersSlice.reducer
