import { combineReducers } from 'redux'
import filters from './filters/filtersSlice'
import data from './data/dataSlice'
import settings from './settings/settingsSlice'

const rootReducer = (asyncReducers) => (state, action) => {
  const combinedReducer = combineReducers({
    filters,
    data,
    settings,
    ...asyncReducers,
  })
  return combinedReducer(state, action)
}

export default rootReducer
