import * as React from 'react'
import Autocomplete from '@mui/material/Autocomplete'
import Checkbox from '@mui/material/Checkbox'
import TextField from '@mui/material/TextField'
import CircularProgress from '@mui/material/CircularProgress'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import Avatar from '@mui/material/Avatar'
import { styled, lighten, darken } from '@mui/system'

export default function MultipleSelect({
  label,
  items,
  width,
  setValue,
  defaultValue = [],
  loading = false,
  value = [],
}) {
  const icon = <CheckBoxOutlineBlankIcon fontSize='small' />
  const checkedIcon = <CheckBoxIcon fontSize='small' />

  return (
    <Autocomplete
      sx={{ width }}
      onChange={(event, newValue) => {
        setValue(newValue)
      }}
      size='small'
      multiple
      value={value}
      isOptionEqualToValue={(option, newValue) => {
        return option.id === newValue.id
      }}
      noOptionsText='Данные не найдены'
      limitTags={1}
      disableCloseOnSelect
      options={items}
      defaultValue={defaultValue}
      getOptionLabel={(option) => option.name}
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            style={{ marginRight: 5 }}
            checked={selected}
          />
          {option?.avatar && (
            <Avatar src={option.avatar} sx={{ width: 24, height: 24, mr: 1 }} />
          )}
          {option.name}
        </li>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? <CircularProgress color='inherit' size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  )
}
