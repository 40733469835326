import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Stack from '@mui/material/Stack'
import Box from '@mui/material/Box'
import MenuItem from '@mui/material/MenuItem'
import TextField from '@mui/material/TextField'
import LoadingButton from '@mui/lab/LoadingButton'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import SendIcon from '@mui/icons-material/Send'
import InsightsIcon from '@mui/icons-material/Insights'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import SettingsIcon from '@mui/icons-material/Settings'
import DatePicker from '../ui/DatePicker'
import dayjs from 'dayjs'
import 'dayjs/locale/ru'
import quarterOfYear from 'dayjs/plugin/quarterOfYear'
import {
  setSelectDateStart,
  setSelectDateFinish,
  setSelectPeriod,
} from '../../redux/filters/filtersSlice'
import { setOpenDrawer } from '../../redux/settings/settingsSlice'
import { getCalls, setOpenChart } from '../../redux/data/dataSlice'

function FiltersDate() {
  const dispatch = useDispatch()
  dayjs.locale('ru')
  dayjs.extend(quarterOfYear)

  const [prevCount, setPrevCount] = useState(0)

  const { dateStart, dateFinish, period } = useSelector((state) => state.filters)

  const { loadingData, warning, sourceData } = useSelector((state) => state.data)

  function updatePeriod(value, count) {
    if (count === 0) {
      setPrevCount(0)
      dispatch(setSelectDateStart(dayjs().startOf(value).format('YYYY-MM-DD')))
      dispatch(setSelectDateFinish(dayjs().format('YYYY-MM-DD')))
    } else {
      dispatch(
        setSelectDateStart(
          dayjs().subtract(count, value).startOf(value).format('YYYY-MM-DD'),
        ),
      )
      dispatch(
        setSelectDateFinish(
          dayjs().subtract(count, value).endOf(value).format('YYYY-MM-DD'),
        ),
      )
    }
    dispatch(setSelectPeriod(value))
  }

  useEffect(() => {
    updatePeriod(period, prevCount)
  }, [prevCount])

  return (
    <Stack
      direction='row'
      justifyContent='space-between'
      alignItems='center'
      sx={{ pb: 2 }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          px: 2,
        }}
      >
        <Button
          onClick={() => dispatch(setOpenDrawer(true))}
          startIcon={<SettingsIcon />}
          variant='text'
          size='large'
        >
          Настройки
        </Button>
      </Box>
      <Stack
        direction='row'
        justifyContent='space-between'
        alignItems='center'
        spacing={2}
      >
        <TextField
          sx={{ width: 120 }}
          size='small'
          value={period}
          onChange={(e) => updatePeriod(e.target.value, 0)}
          variant='outlined'
          label='Период'
          select
        >
          <MenuItem value={'day'}>День</MenuItem>
          <MenuItem value={'week'}>Неделя</MenuItem>
          <MenuItem value={'month'}>Месяц</MenuItem>
          <MenuItem value={'quarter'}>Квартал</MenuItem>
        </TextField>
        <IconButton onClick={() => setPrevCount((prev) => prev + 1)}>
          <ArrowBackIosIcon />
        </IconButton>
        <DatePicker
          label='Период от'
          value={dayjs(dateStart)}
          setValue={(date) =>
            dispatch(setSelectDateStart(dayjs(date).format('YYYY-MM-DD')))
          }
          minDate={dayjs().subtract(1, 'year')}
          maxDate={dayjs(dateFinish)}
        />
        <DatePicker
          label='Период до'
          value={dayjs(dateFinish)}
          setValue={(date) =>
            dispatch(setSelectDateFinish(dayjs(date).format('YYYY-MM-DD')))
          }
          minDate={dayjs(dateStart)}
          maxDate={dayjs()}
        />
        <IconButton
          disabled={prevCount === 0}
          onClick={() => setPrevCount((prev) => prev - 1)}
        >
          <ArrowForwardIosIcon />
        </IconButton>
        <LoadingButton
          loading={loadingData}
          endIcon={<SendIcon />}
          disabled={warning !== ''}
          variant='outlined'
          size='large'
          onClick={() => dispatch(getCalls())}
        >
          Показать
        </LoadingButton>
      </Stack>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          px: 2,
        }}
      >
        <Button
          onClick={() => dispatch(setOpenChart(true))}
          startIcon={<InsightsIcon />}
          disabled={warning !== '' || loadingData || sourceData?.length === 0}
          variant='text'
          size='large'
        >
          Графики
        </Button>
      </Box>
    </Stack>
  )
}

export default FiltersDate
