import React from 'react'
import {
  DataGrid,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
} from '@mui/x-data-grid'
import { GridLocaleText } from '../../locales/tableGridLocale'
import { useSelector, useDispatch } from 'react-redux'
import {
  setFilterModel,
  setСolumns,
  setPageSize,
} from '../../redux/settings/settingsSlice'
import Footer from './Footer'
import FullScreenDialog from '../detail/Dialog'
import Chart from '../chart'
import row from './Row'

export default function BasicTable({}) {
  const dispatch = useDispatch()
  const { calls, loadingData } = useSelector((state) => state.data)
  const { columns, filterModel, pageSize } = useSelector((state) => state.settings)

  const columnGroupingModel = [
    {
      groupId: 'calls',
      headerName: 'Количество звонков',
      children: [
        { field: 'productiveCalls' },
        { field: 'successCalls' },
        { field: 'failedCalls' },
        { field: 'missedCalls' },
        { field: 'sumCalls' },
      ],
    },
    {
      groupId: 'times',
      headerName: 'Время звонков',
      children: [
        { field: 'productiveTime' },
        { field: 'successTime' },
        { field: 'averageTime' },
        { field: 'sumTime' },
      ],
    },
    {
      groupId: 'effective',
      headerName: 'Эффективность звонков',
      children: [{ field: 'effectProdCalls' }, { field: 'effectSuccessCalls' }],
    },
  ]

  function Toolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <GridToolbarExport printOptions={{ disableToolbarButton: true } } csvOptions={{
          allColumns: true,
          delimiter: ';',
          fileName: 'Отчет по звонкам',
          utf8WithBom: true
        }}/>
      </GridToolbarContainer>
    )
  }

  return (
    <div style={{ display: 'flex', height: '100%' }}>
      <div style={{ flexGrow: 1, height: 420 }}>
        <DataGrid
          autoHeight
          rows={calls}
          loading={loadingData}
          columns={row}
          experimentalFeatures={{ columnGrouping: true }}
          columnGroupingModel={columnGroupingModel}
          pageSize={pageSize}
          onPageSizeChange={(newPageSize) => dispatch(setPageSize(newPageSize))}
          rowsPerPageOptions={[10, 20, 30, 40, 50]}
          disableSelectionOnClick
          components={{ Toolbar, Footer }}
          localeText={GridLocaleText}
          getRowHeight={() => 'auto'}
          getEstimatedRowHeight={() => 200}
          filterModel={filterModel}
          onFilterModelChange={(newFilterModel) =>
            dispatch(setFilterModel(newFilterModel))
          }
          columnVisibilityModel={columns}
          onColumnVisibilityModelChange={(newModel) =>
            dispatch(setСolumns(newModel))
          }
          sx={{
            '&.MuiDataGrid-root .MuiDataGrid-cell': { borderBottomColor: '#525c69' },
            '&.MuiDataGrid-root .MuiDataGrid-columnHeaders': {
              borderBottomColor: '#525c69',
            },
            '&.MuiDataGrid-root .MuiDataGrid-footerContainer': {
              borderTopColor: '#525c69',
            },
            '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': { py: '8px' },
            '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': { py: '15px' },
            '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell': {
              py: '22px',
            },
            '&.MuiDataGrid-root .MuiDataGrid-iconSeparator': { color: '#525c69' },
            '&.MuiDataGrid-root .MuiDataGrid-columnHeader--filledGroup .MuiDataGrid-columnHeaderTitleContainer':
              { borderBottomColor: '#525c69' },
          }}
        />
        <FullScreenDialog />
        <Chart />
      </div>
    </div>
  )
}
